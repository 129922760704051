import { getConfig } from '../config/portal';

export const getLeadTypes = () =>
  Object.values(getConfig().leads.types)
    .map(({ type }) => type);

export const getBoatLeadTypeOptions = () =>
  getConfig().leads.types.boat;

export const getPartyLeadTypeOptions = () =>
  getConfig().leads.types.party;

export const getDealerLeadTypeOptions = () =>
  getConfig().leads.types.dealer;

import validation from '@dmm/lib-common/lib/validation';
import _ from 'lodash';

export default function validate(val, type) {
  let pass = true;
  switch (type) {
  case 'text':
    pass = !_.isEmpty(val);
    break;
  case 'name':
    pass = validation.isValidName(val);
    break;
  case 'fullName':
    pass = validation.isValidFirstSecondName(val);
    break;
  case 'email':
    pass = (validation.isValidEmail(val));
    break;
  case 'phone':
    pass = true;
    break;
  case 'phoneNumber':
    pass = validation.isValidPhoneNumber(val);
    break;
  case 'zip':
    pass = validation.isValidUsZipCode(val);
    break;
  case 'country':
    pass = !_.isEmpty(_.trim(val));
    break;
  default:
    break;
  }
  return pass;
}

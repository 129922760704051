import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import validate from '../../utils/dataValidation';
import './styles.css';

class ValidatedInput extends Component {
  state = {
    valid: false
  };

  componentDidMount() {
    this.isValid(this.props.validation, get(this.props, 'defaultValue', ''));
  }

  isValid(validation, value) {
    const valid = validate(value, validation);
    this.setState({ valid });
    return valid;
  }

  getInput = () => {
    if (this.props.type === 'textarea') {
      return (
        <textarea
          placeholder={this.props.placeholderText}
          defaultValue={this.props.defaultValue}
          id={this.props.id}
          name={this.props.name}
          aria-label={this.props.labelText}
          onChange={(e) =>
            this.props.onChange(
              this.props.name,
              this.isValid(this.props.validation, e.target.value),
              e.target.value
            )
          }
        />
      );
    }

    if (this.props.type === 'select') {
      return (
        <select
          defaultValue={this.props.defaultValue}
          id={this.props.id}
          name={this.props.name}
          onChange={(e) =>
            this.props.onChange(
              this.props.name,
              this.isValid(this.props.validation, e.target.value),
              e.target.value
            )
          }
        >
          <option className="placeholder-option" value="" disabled>
            {this.props.placeholderText}
          </option>
          {map(this.props.data, (value, key) => (
            <option value={key} key={key}>
              {value}
            </option>
          ))}
        </select>
      );
    }

    return (
      <input
        placeholder={this.props.placeholderText}
        defaultValue={this.props.defaultValue}
        id={this.props.id}
        aria-labelledby={`${this.props.id}-label`}
        name={this.props.name}
        type={this.props.type}
        onChange={(e) =>
          this.props.onChange(
            this.props.name,
            this.isValid(this.props.validation, e.target.value),
            e.target.value
          )
        }
      />
    );
  };

  render() {
    return (
      <fieldset
        className={classnames(
          'validated-input',
          `validated-input-${this.props.name}`,
          this.props.mode,
          {
            hidden: this.props.hidden,
            error: !this.state.valid && this.props.showError,
            showLabel: !this.props.hiddenLabel
          }
        )}
      >
        <label
          className={classnames({ hidden: this.props.hiddenLabel })}
          htmlFor={this.props.id}
          id={`${this.props.id}-label`}
        >
          {this.props.labelText}
        </label>
        {this.getInput()}
      </fieldset>
    );
  }
}

ValidatedInput.defaultProps = {
  hidden: false,
  hiddenLabel: true,
  showError: false,
  placeholderText: '',
  labelText: ''
};

ValidatedInput.propTypes = {
  /** Input initial value */
  defaultValue: PropTypes.any,
  /** If true, the input is not displayed */
  hidden: PropTypes.bool,
  /** If false, the label is displayed */
  hiddenLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  /** Input description, not displayed */
  labelText: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** Callback to parent element */
  onChange: PropTypes.func,
  /** Input type */
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['text', 'tel', 'number', 'textarea', 'select']),
    PropTypes.string
  ]).isRequired,
  /** If true, applies the error format */
  showError: PropTypes.bool,
  validation: PropTypes.oneOf([
    'text',
    'name',
    'fullName',
    'email',
    'phone',
    'zip',
    'country'
  ]),
  data: PropTypes.object
};

export default ValidatedInput;
